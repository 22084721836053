exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-integrity-js": () => import("./../../../src/pages/business-integrity.js" /* webpackChunkName: "component---src-pages-business-integrity-js" */),
  "component---src-pages-careers-big-data-and-db-specialist-js": () => import("./../../../src/pages/careers/big-data-and-db-specialist.js" /* webpackChunkName: "component---src-pages-careers-big-data-and-db-specialist-js" */),
  "component---src-pages-careers-data-scientist-js": () => import("./../../../src/pages/careers/data-scientist.js" /* webpackChunkName: "component---src-pages-careers-data-scientist-js" */),
  "component---src-pages-careers-frontend-dev-js": () => import("./../../../src/pages/careers/frontend-dev.js" /* webpackChunkName: "component---src-pages-careers-frontend-dev-js" */),
  "component---src-pages-careers-java-backend-dev-js": () => import("./../../../src/pages/careers/java-backend-dev.js" /* webpackChunkName: "component---src-pages-careers-java-backend-dev-js" */),
  "component---src-pages-careers-join-us-js": () => import("./../../../src/pages/careers/join-us.js" /* webpackChunkName: "component---src-pages-careers-join-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-project-manager-js": () => import("./../../../src/pages/careers/project-manager.js" /* webpackChunkName: "component---src-pages-careers-project-manager-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-electricity-and-gas-js": () => import("./../../../src/pages/industries/electricity-and-gas.js" /* webpackChunkName: "component---src-pages-industries-electricity-and-gas-js" */),
  "component---src-pages-industries-government-js": () => import("./../../../src/pages/industries/government.js" /* webpackChunkName: "component---src-pages-industries-government-js" */),
  "component---src-pages-industries-insurance-js": () => import("./../../../src/pages/industries/insurance.js" /* webpackChunkName: "component---src-pages-industries-insurance-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-industries-manufacturing-js": () => import("./../../../src/pages/industries/manufacturing.js" /* webpackChunkName: "component---src-pages-industries-manufacturing-js" */),
  "component---src-pages-industries-telco-js": () => import("./../../../src/pages/industries/telco.js" /* webpackChunkName: "component---src-pages-industries-telco-js" */),
  "component---src-pages-industries-transportation-js": () => import("./../../../src/pages/industries/transportation.js" /* webpackChunkName: "component---src-pages-industries-transportation-js" */),
  "component---src-pages-information-security-js": () => import("./../../../src/pages/information-security.js" /* webpackChunkName: "component---src-pages-information-security-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-ai-fraud-detection-js": () => import("./../../../src/pages/solutions/ai-fraud-detection.js" /* webpackChunkName: "component---src-pages-solutions-ai-fraud-detection-js" */),
  "component---src-pages-solutions-date-js": () => import("./../../../src/pages/solutions/date.js" /* webpackChunkName: "component---src-pages-solutions-date-js" */),
  "component---src-pages-solutions-gamayun-js": () => import("./../../../src/pages/solutions/gamayun.js" /* webpackChunkName: "component---src-pages-solutions-gamayun-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */)
}

